<template>
  <section>
    <!--PC-->
    <template v-if="isMobile === false">
    <div class="fullpage-container">
      <div class="fullpage-wp" v-fullpage="opts" ref="myFullpage">
        <div class="page-1 page">
          <div class="top-banner">
            <v-carousel
              cycle
              hide-delimiter-background
              delimiter-icon=""
              :show-arrows="false"
              :height="sliderHeight"
              v-if="info.banners"
            >
              <v-carousel-item
                v-for="(slider, i) in info.banners"
                :key="i"
                :src="slider.img"
              ></v-carousel-item>
            </v-carousel>
          </div>
        </div>
        <div class="page-2 page">
          <v-layout wrap>
            <v-flex md12 text-center>
              <p class="page-h" v-animate="{value: 'fadeInUp', delay: 0}">{{page2.h}}</p>
              <p class="page-2-p2" v-animate="{value: 'fadeInUp', delay: 400}">{{page2.p2}}</p>
              <p class="page-2-p3" v-animate="{value: 'fadeInUp', delay: 800}" v-html="page2.p3"></p>
              <p class="page-2-p4" v-animate="{value: 'fadeInUp', delay: 1200}">{{page2.p4}}</p>
            </v-flex>
            <v-flex md12 text-center>
              <div class="div-1280">
                <v-layout wrap>
                  <v-flex v-for="item in page2.items" :key="item.key" md4 text-center>
                    <div v-animate="{value: 'fadeInUp', delay: 1400}"><img class="page-2-img" :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/hsm3/page2-' + item.key + '.png'"/></div>
                    <p v-animate="{value: 'fadeInUp', delay: 1600}" class="page-2-p5">{{item.title}}</p>
                  </v-flex>
                </v-layout>
              </div>
            </v-flex>
          </v-layout>
        </div>
        <div class="page-3 page">
          <v-layout wrap>
            <v-flex md12 text-center>
              <p class="page-h" v-animate="{value: 'fadeInUp', delay: 0}">{{page3.h}}</p>
            </v-flex>
            <v-flex md12 text-center style="margin-top: 54px;">
              <div class="div-1280">
                <div class="d-flex justify-space-between">
                  <v-card
                    v-for="item in page3.items" :key="item.key"
                    class="transparent"
                    flat
                    tile
                  >
                    <div v-animate="{value: 'fadeInUp', delay: 400}"><img class="page-3-img" :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/hsm3/page3-' + item.key + '.png'"/></div>
                    <p class="page-3-p1" v-animate="{value: 'fadeInUp', delay: 600}">{{item.p1}}</p>
                    <p class="page-3-p2" v-animate="{value: 'fadeInUp', delay: 800}">{{item.p2}}</p>
                  </v-card>
                </div>
              </div>
            </v-flex>
          </v-layout>
        </div>
        <div class="page-4 page">
          <v-layout wrap>
            <v-flex md12 text-center>
              <p class="page-h" v-animate="{value: 'fadeInUp', delay: 0}">{{page4.h}}</p>
              <p class="page-p" v-animate="{value: 'fadeInUp', delay: 400}">{{page4.p}}</p>
            </v-flex>
            <v-flex md12 text-center>
              <div class="div-844">
                <div class="d-flex justify-space-between">
                  <v-card
                    v-for="item in page4.items" :key="item.key"
                    class="transparent"
                    flat
                    tile
                  >
                    <div v-animate="{value: 'fadeInUp', delay: 800}"><img class="page-4-img" :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/hsm3/page4-' + item.key + '.png'"/></div>
                    <p class="page-2-p5" v-animate="{value: 'fadeInUp', delay: 1000}">{{item.p}}</p>
                  </v-card>
                </div>
              </div>
            </v-flex>
            <v-flex md12 text-center v-animate="{value: 'fadeInUp', delay: 1200}">
              <a href="/scheme#improve" class="more-btn"><span class="more-btn-text">了解更多</span><span class="more-btn-icon"></span></a>
            </v-flex>
          </v-layout>
        </div>
        <div class="page-5 page">
          <v-layout wrap>
            <v-flex md12 text-center>
              <p class="page-h" v-animate="{value: 'fadeInUp', delay: 0}">{{page5.h}}</p>
              <p class="page-p" v-animate="{value: 'fadeInUp', delay: 400}">{{page5.p}}</p>
            </v-flex>
            <v-flex md12 text-center>
              <div class="div-844">
                <div class="d-flex justify-space-between">
                  <v-card
                    v-for="item in page5.items" :key="item.key"
                    class="transparent"
                    flat
                    tile
                  >
                    <div v-animate="{value: 'fadeInUp', delay: 800}"><img class="page-4-img" :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/hsm3/page5-' + item.key + '.png'"/></div>
                    <p v-animate="{value: 'fadeInUp', delay: 1000}" class="page-2-p5">{{item.p}}</p>
                  </v-card>
                </div>
              </div>
            </v-flex>
          </v-layout>
        </div>
        <div class="page-6 page">
          <v-layout wrap>
            <v-flex md12 text-center>
              <p class="page-h" v-animate="{value: 'fadeInUp', delay: 0}">{{page6.h}}</p>
              <p class="page-p" v-animate="{value: 'fadeInUp', delay: 400}">{{page6.p}}</p>
            </v-flex>
            <v-flex md12 text-center>
              <div class="div-844">
                <div class="d-flex justify-space-between">
                  <v-card
                    v-for="item in page6.items" :key="item.key"
                    class="transparent"
                    flat
                    tile
                  >
                    <div v-animate="{value: 'fadeInUp', delay: 800}"><img class="page-4-img" :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/hsm3/page6-' + item.key + '.png'"/></div>
                    <p v-animate="{value: 'fadeInUp', delay: 1000}" class="page-2-p5">{{item.p}}</p>
                  </v-card>
                </div>
              </div>
            </v-flex>
          </v-layout>
        </div>
        <div class="page-7 page">
          <v-layout wrap>
            <v-flex md12 text-center>
              <p class="page-h" v-animate="{value: 'fadeInUp', delay: 0}">客户环境改善案例</p>
            </v-flex>
            <v-flex md12 text-center style="margin-top: 54px;" v-if="info.customers">
              <div class="div-1280">
                <v-layout wrap>
                  <v-flex md12>
                    <template>
                      <v-tabs
                        class="customer-tabs"
                        center-active
                        centered
                        hide-slider
                        v-model="customerIndex"
                      >
                        <v-tab :ripple="false" v-for="(c, ci) in info.customers" :key="ci">
                          <div class="page-7-circle" v-animate="{value: 'fadeInUp', delay: 400}">
                            <img :src="c.img"/>
                          </div>
                        </v-tab>
                      </v-tabs>
                    </template>
                  </v-flex>
                  <v-flex md12>
                    <p class="page-7-name" v-animate="{value: 'fadeInUp', delay: 800}">{{customer.name}}</p>
                  </v-flex>
                  <v-flex md12>
                    <p class="page-7-label" v-animate="{value: 'fadeInUp', delay: 1000}">{{customer.label}}</p>
                  </v-flex>
                  <v-flex md12>
                    <div class="div-909">
                      <p class="page-7-desc" v-animate="{value: 'fadeInUp', delay: 1200}">{{customer.content}}</p>
                    </div>
                  </v-flex>
                </v-layout>
              </div>
            </v-flex>
          </v-layout>
        </div>
        <div class="page-8 page">
          <v-layout wrap>
            <v-flex md12 text-center>
              <p class="page-h" v-animate="{value: 'fadeInUp', delay: 0}">社会责任</p>
            </v-flex>
            <v-flex md12 text-center>
              <div class="page-8-div">
                <v-layout wrap>
                  <v-flex md12 text-center>
                    <p class="page-8-p" v-animate="{value: 'fadeInUp', delay: 400}">&nbsp;低碳生活，健康睡眠</p>
                  </v-flex>
                  <v-flex md12 text-center v-animate="{value: 'fadeInUp', delay: 800}">
                    <a href="/about#responsibility" class="more-btn"><span class="more-btn-text">了解更多</span><span class="more-btn-icon"></span></a>
                  </v-flex>
                </v-layout>
              </div>
            </v-flex>
          </v-layout>
        </div>
        <div class="page-9 page">
          <v-layout wrap>
            <v-flex md12 text-center>
              <p class="page-h" v-animate="{value: 'fadeInUp', delay: 0}">新闻资讯</p>
            </v-flex>
            <v-flex md12 text-center style="margin-top: 32px;" v-if="info.news">
              <div class="div-1280">
                <div class="d-flex justify-space-between">
                  <div class="page-9-news" v-for="(n, ni) in info.news" :key="ni">
                    <a :href="'/new/' + n.id">
                      <div class="page-9-img" v-animate="{value: 'fadeInDown', delay: 400}"><img :src="n.img"/></div>
                      <div class="page-9-title" v-animate="{value: 'fadeInUp', delay: 800}">{{n.title}}</div>
                      <div class="page-9-action" v-animate="{value: 'fadeInUp', delay: 1000}">
                        <span class="page-9-type">企业动态</span>
                        <span class="page-9-date">{{n.create_time.substring(0, 10)}}</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </v-flex>
            <v-flex md12 text-center v-animate="{value: 'fadeInUp', delay: 1200}">
              <a href="/news" class="more-btn" style="margin-top: 30px;"><span class="more-btn-text">了解更多</span><span class="more-btn-icon"></span></a>
            </v-flex>
          </v-layout>
        </div>
      </div>
    </div>
    </template>
    <!--移动端-->
    <template v-if="isMobile" style="background:#F6F6F6;">
      <div>
        <template>
          <swiper class="swiper mswiper" :options="swiperOption">
            <swiper-slide v-for="(slider, mbi) in info.mbanners" :key="mbi">
                <img :src="slider.img" style="display:block; font-size: 0; width:100%; min-height: 240px;"/>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </template>
      </div>
      <div>
        <div class="mpage-2">
          <v-layout wrap>
            <v-flex xs12 sm12 text-center>
              <p class="mpage-h wow fadeInUp">{{page2.h}}</p>
              <p class="mpage-2-p2 wow fadeInUp" data-wow-delay="0.2s">{{page2.p2}}</p>
              <p class="mpage-2-p3 wow fadeInUp" data-wow-delay="0.4s" v-html="page2.p3"></p>
              <p class="mpage-2-p4 wow fadeInUp" data-wow-delay="0.6s">{{page2.p4}}</p>
            </v-flex>
            <v-flex xs12 sm12 text-center>
              <div class="div-1280">
                <v-layout wrap>
                  <v-flex v-for="item in page2.items" :key="item.key" xs4 sm4 pa-3 text-center>
                    <div class="wow fadeInUp"><img class="mpage-2-img" :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/hsm3/page2-' + item.key + '.png'"/></div>
                    <p class="mpage-2-p5 wow fadeInUp" data-wow-delay="0.8s">{{item.title}}</p>
                  </v-flex>
                </v-layout>
              </div>
            </v-flex>
          </v-layout>
        </div>
        <div class="mpage-3">
          <v-layout wrap>
            <v-flex md12 text-center>
              <p class="mpage-h wow fadeInUp">{{page3.h}}</p>
            </v-flex>
            <v-flex xs4 sm12 pl-3 pr-3 pt-4 text-center v-for="item in page3.items" :key="item.key">
              <div><img class="mpage-3-img wow fadeInUp" data-wow-delay="0.2s" :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/hsm3/page3-' + item.key + '.png'"/></div>
              <p class="mpage-3-p1 wow fadeInUp" data-wow-delay="0.4s">{{item.p1}}</p>
              <p class="mpage-3-p2 wow fadeInUp" data-wow-delay="0.6s">{{item.p2}}</p>
            </v-flex>
          </v-layout>
        </div>
        <div class="mpage-4">
          <v-layout wrap>
            <v-flex xs12 text-center>
              <p class="mpage-h wow fadeInUp">{{page4.h}}</p>
              <p class="mpage-p wow fadeInUp" data-wow-delay="0.2s">{{page4.p}}</p>
            </v-flex>
            <v-flex xs3 text-center v-for="item in page4.items" :key="item.key">
                <div><img class="mpage-4-img wow fadeInUp" :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/hsm3/page4-' + item.key + '.png'"/></div>
                <p class="mpage-2-p5 wow fadeInUp">{{item.p}}</p>
            </v-flex>
            <v-flex xs12 text-center>
              <a href="/scheme#improve" class="more-btn"><span class="more-btn-text wow fadeInUp">了解更多</span><span class="more-btn-icon"></span></a>
            </v-flex>
          </v-layout>
        </div>
        <div class="mpage-5">
          <v-layout wrap>
            <v-flex xs12 text-center>
              <p class="mpage-h wow fadeInUp">{{page5.h}}</p>
              <p class="mpage-p wow fadeInUp">{{page5.p}}</p>
            </v-flex>
            <v-flex xs4 text-center v-for="item in page5.items" :key="item.key">
              <div><img class="mpage-4-img wow fadeInUp" :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/hsm3/page5-' + item.key + '.png'"/></div>
              <p class="mpage-2-p5 wow fadeInUp">{{item.p}}</p>
            </v-flex>
          </v-layout>
        </div>
        <div class="mpage-6">
          <v-layout wrap>
            <v-flex xs12 text-center>
              <p class="mpage-h wow fadeInUp">{{page6.h}}</p>
              <p class="mpage-p wow fadeInUp">{{page6.p}}</p>
            </v-flex>
            <v-flex xs4 text-center v-for="item in page6.items" :key="item.key">
              <div><img class="mpage-4-img wow fadeInUp" :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/hsm3/page6-' + item.key + '.png'"/></div>
              <p class="mpage-2-p5 wow fadeInUp">{{item.p}}</p>
            </v-flex>
          </v-layout>
        </div>
        <div class="mpage-7">
          <v-layout wrap>
            <v-flex xs12 text-center>
              <p class="mpage-h wow fadeInUp">客户环境改善案例</p>
            </v-flex>
            <v-flex xs12 text-center v-if="info.customers">
              <template>
                <swiper class="swiper" :options="swiperOption">
                  <swiper-slide v-for="(c, ci) in info.customers" :key="ci">
                    <div class="mpage-7-case">
                      <div class="mpage-7-circle wow fadeInUp" data-wow-delay="0.4s">
                        <img :src="c.img"/>
                      </div>
                      <div>
                        <span class="mpage-7-name">{{c.name}}</span>
                        <span class="mpage-7-label">{{c.label}}</span>
                      </div>
                      <p class="mpage-7-desc wow fadeInUp" data-wow-delay="1.2s">{{c.content}}</p>
                    </div>
                  </swiper-slide>
                  <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
              </template>
            </v-flex>
          </v-layout>
        </div>
        <div class="mpage-8">
          <v-layout wrap>
            <v-flex xs12 text-center>
              <p class="mpage-h wow fadeInUp">社会责任</p>
            </v-flex>
            <v-flex xs12 text-center style="padding: 10px;">
              <div class="mpage-8-div">
                <v-layout wrap>
                  <v-flex xs12 text-center>
                    <p class="mpage-8-p wow fadeInUp" data-wow-delay="0.3s">&nbsp;低碳生活，健康睡眠</p>
                  </v-flex>
                  <v-flex xs12 text-center>
                    <a href="/about#responsibility" class="more-btn wow fadeInUp" data-wow-delay="0.6s"><span class="more-btn-text">了解更多</span><span class="more-btn-icon"></span></a>
                  </v-flex>
                </v-layout>
              </div>
            </v-flex>
          </v-layout>
        </div>
        <div class="mpage-9">
          <v-layout wrap>
            <v-flex xs12 text-center>
              <p class="mpage-h wow fadeInUp">新闻资讯</p>
            </v-flex>
            <v-flex xs12 text-center v-if="info.news">
              <template>
                <swiper class="swiper" :options="swiperOption">
                  <swiper-slide v-for="(n, ni) in info.news" :key="ni">
                    <div class="mpage-9-news">
                      <a :href="'/new/' + n.id">
                        <div class="mpage-9-img wow fadeInDown" data-wow-delay="0.4s"><img :src="n.img"/></div>
                        <div class="mpage-9-title wow fadeInUp" data-wow-delay="0.6s">{{n.title}}</div>
                        <div class="mpage-9-action wow fadeInUp" data-wow-delay="0.8s">
                          <span class="mpage-9-type">企业动态</span>
                          <span class="mpage-9-date">{{n.create_time.substring(0, 10)}}</span>
                        </div>
                      </a>
                    </div>
                  </swiper-slide>
                  <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
              </template>
            </v-flex>
            <v-flex xs12 text-center>
              <a href="/news" class="more-btn"><span class="more-btn-text wow fadeInUp">了解更多</span><span class="more-btn-icon"></span></a>
            </v-flex>
          </v-layout>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper/dist/vue-awesome-swiper.js'
export default {
  components: {
    swiper,
    swiperSlide
  },
  data: () => ({
    isMobile: false,
    sliders: [],
    sliderHeight: 0,
    pageId: 0,
    mpageId: 18,
    innerWidth: window.innerWidth,
    opts: {},
    defferScroll: function (event) {
      event.preventDefault()
    },
    page2: {
      h: '你是睡眠“困”难户吗？',
      p2: '信息来源：中国睡眠研究会',
      p3: '人的一生中，约有三分之一的时间是在睡眠中度过。<br/>据统计，我国超3亿人存在睡眠障碍',
      p4: '睡眠困扰的三大表现',
      items: [
        { key: 1, title: '睡不着' },
        { key: 2, title: '睡不深' },
        { key: 3, title: '容易醒' }
      ]
    },
    page3: {
      h: '睡眠障碍不重视，一生受影响',
      items: [
        { key: 1, p1: '记忆力减退', p2: '工作/学习效率下降' },
        { key: 2, p1: '神经内分泌失调', p2: '憔悴、衰老' },
        { key: 3, p1: '免疫力下降', p2: '过敏、亚健康' },
        { key: 4, p1: '情绪易失控', p2: '焦虑、暴躁、抑郁' },
        { key: 5, p1: '诱发疾病', p2: '高血压、糖尿病、心脑血管疾病、帕金森、老年痴呆等' }
      ]
    },
    page4: {
      h: '好睡眠致力于改善国民睡眠环境',
      p: '好睡眠集睡眠环境学、人体工程学、智能化技术，改善影响睡眠的静环意、暗环境、眠环境和嗅环境四大功能，打造24小时全天候深睡环境。',
      items: [
        { key: 1, p: '静环境' },
        { key: 2, p: '暗环境' },
        { key: 3, p: '眠环境' },
        { key: 4, p: '嗅环境' }
      ]
    },
    page5: {
      h: '品质甄选“三无”产品',
      p: '好睡眠团队深谙国内现目前室内睡眠环境痛点，将近20年来掌握的国内外建筑及装饰领域研发、生产、加工、运营方面的成熟经验运用在好睡眠品牌上，率先在行业内推出“三无”产品理念，为用户打造更安静、更健康、更舒适、更环保的睡眠环境。',
      items: [
        { key: 1, p: '无噪音' },
        { key: 2, p: '无漏光' },
        { key: 3, p: '无霉菌' }
      ]
    },
    page6: {
      h: '好管家“三无”服务',
      p: '好睡眠好管家拥有完善的质量管理、精细化的经营运作体系及独创“三无”服务体系，保障服务的质量和效率，确保为用户提供“无尘”、“无扰”、“无忧”的星级服务。',
      items: [
        { key: 1, p: '“无尘”服务' },
        { key: 2, p: '“无扰”服务' },
        { key: 3, p: '“无忧”服务' }
      ]
    },
    page7: {
    },
    page8: {
    },
    page9: {
    },
    customer: {},
    customerIndex: null,
    swiperOption: {
      pagination: {
        el: '.swiper-pagination'
      }
    }
  }),
  created () {
    document.querySelector('#menu_home').classList.add('v-btn--active')
    if (window.innerWidth < 960) {
      this.isMobile = true
    }
    let that = this
    this.opts = {
      start: 0,
      dir: 'v',
      duration: 1000,
      beforeChange: function (currentSlideEl, currenIndex, nextIndex) {
        if (currenIndex === 8) {
          if (window.scrollY > 0) {
            return false
          } else {
            window.scrollTo(0, 0)
          }
        }
      },
      afterChange: function (currentSlideEl, currenIndex) {
        // 最后一页处理
        if (currenIndex === 8) {
          that.setScroll(true)
        } else {
          window.scrollTo(0, 0)
          that.setScroll(false)
        }
        // 滚动一页间隔1秒才能滚动，防止有的浏览器一次滚动两页的情况
        that.$refs.myFullpage.$fullpage.setDisabled(true)
        setTimeout(function () {
          that.$refs.myFullpage.$fullpage.setDisabled(false)
        }, 1000)
      }
    }
    this.sliderHeight = document.documentElement.clientHeight - 96
  },
  mounted: function () {
    this.setScroll(false)
    this.$wow.init()
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.$nextTick(function () {
          this.info.banners = val.banner[this.pageId]
          this.info.mbanners = val.banner[this.mpageId]
          this.info.icp = val.icp
          if (!this.isMobile) {
            this.info.customers = this.expendArr(val.customers, 8)
          } else {
            this.info.customers = val.customers
          }
          this.customerIndex = Math.ceil(this.info.customers.length / 2) - 1
          this.customer = this.info.customers[this.customerIndex]
          this.info.news = val.news[2].slice(0, 3)
        })
      },
      deep: true
    },
    customerIndex: {
      handler: function (val, oldVal) {
        this.$nextTick(function () {
          this.customer = this.info.customers[val]
        })
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      let mbanners = {}
      let icp = ''
      let customers = []
      let news = []
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      if (this.$store.getters.companyInfo.banner) {
        mbanners = this.$store.getters.companyInfo.banner[this.mpageId]
      }
      if (this.$store.getters.companyInfo.icp) {
        icp = this.$store.getters.companyInfo.icp
      }
      if (this.$store.getters.companyInfo.customers) {
        if (!this.isMobile) {
          customers = this.expendArr(this.$store.getters.companyInfo.customers, 8)
        } else {
          customers = this.$store.getters.companyInfo.customers
        }
      }
      if (this.$store.getters.companyInfo.news) {
        news = this.$store.getters.companyInfo.news[2].slice(0, 3)
      }
      return {
        banners: banners,
        mbanners: mbanners,
        customers: customers,
        news: news,
        icp: icp
      }
    }
  },
  methods: {
    setScroll (status) {
      if (!this.isMobile) {
        if (status) {
          document.body.removeEventListener('touchmove', this.defferScroll, { passive: false })
          document.body.removeEventListener('wheel', this.defferScroll, { passive: false })
        } else {
          document.body.addEventListener('touchmove', this.defferScroll, { passive: false })
          document.body.addEventListener('wheel', this.defferScroll, { passive: false })
        }
      }
    },
    homeGoFirstPage () {
      this.$refs.myFullpage.$fullpage.moveTo(0)
    },
    expendArr (arr, times) {
      let newArr = []
      for (let i = 0; i < times; i++) {
        arr.forEach(function (item) {
          newArr.push(item)
        })
      }
      return newArr
    }
  }
}
</script>
<style lang="css">
  @import '~@/assets/css/home.css';
</style>
